import * as React from "react";
import { Alert, Modal, Accordion, Button, Carousel } from "flowbite-react";
const Photo02_full = require("../assets/photo02-full.jpeg");
const Photo03 = require("../assets/photo03.jpeg");
const english = require("../assets/english-certificate.png");

export default function Certification() {
  return (
    <div class="m-10 mx-auto max-w-6xl">
        
    </div>
  );
}